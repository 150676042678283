<template>
  <transition name="banner-slide">
    <div
      v-if="show"
      class="h-10 bg-purple-700 border-b-4 border-purple-800 border-solid"
    >
      <div class="text-center text-white">
        <span
          class="inline-block mx-20 align-middle lg:text-2xl md:text-base sm:text-base lg:my-0 sm:my-2"
        >
          {{ trans('NO CONNECTION') }}
        </span>
        <button
          class="inline-block align-middle focus:outline-none"
          @click="closeBanner"
        >
          >
          <svg
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex'
import trans from '@/utils/trans'

export default {
  props: {
    show: {
      type: Boolean,
    },
  },

  methods: {
    ...mapActions({
      setBannerStatus: 'banner/setBannerStatus',
    }),

    closeBanner() {
      this.setBannerStatus(false)
    },

    trans,
  },
}
</script>

<style scoped>
.banner-slide-enter-active {
  animation: slideYIn 0.5s;
}
.banner-slide-leave-active {
  animation: slideYOut 0.5s;
}
@keyframes slideYIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
  }
}

@keyframes slideYOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translateY(-15px);
  }
}
</style>
