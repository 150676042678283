export const state = {
  show: false,
}

export const getters = {
  showBanner: state => state.show,
}

export const mutations = {
  SET_DATA(state, payload) {
    state.show = payload
  },
}

export const actions = {
  setBannerStatus({ commit }, payload) {
    commit('SET_DATA', payload)
  },
}
