import axios from 'axios'
import qs from 'qs'

axios.defaults.withCredentials = true
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'brackets' })

axios.interceptors.request.use(request => {
  return request
})

axios.interceptors.response.use(
  response => response,
  error => {
    let { status } = error.response
    switch (status) {
      case 401:
        break
      case 403:
        // location.replace(`${process.env.VUE_APP_BASE_URL}/unauthorized`);
        break
      case 429:
        window.bus.$emit(
          'notification',
          'error',
          'The user has sent too many requests in a given amount of time intended for use with rate-limiting schemes!'
        )
        // Cancel all pending axios requests
        window.cancelSource.cancel()
        break
      case 500:
        window.bus.$emit(
          'notification',
          'error',
          'The server is not responding, please contact your administrator.'
        )
        break
    }
    return Promise.reject(error)
  }
)
