import { createApp } from 'vue'
import App from './App.vue'
import './plugins'
import './assets/tailwind.css'
import AudioRecorder from 'audio-recorder-polyfill'
import axios from 'axios'
import store from './state/store'
import router from './router'
import eventBus from './utils/eventBus'
import PortalVue from 'portal-vue'
import { MediaRecorder, register } from 'extendable-media-recorder'
import { connect } from 'extendable-media-recorder-wav-encoder'

if (!window.MediaRecorder) {
  window.MediaRecorder = AudioRecorder
}else{
  await register(await connect())
  window.MediaRecorder = MediaRecorder
}

// This variable is to abort all axios requests
// if ever we have an error 429
const { CancelToken } = axios
window.cancelSource = CancelToken.source()
window.bus = eventBus

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then(
      () => { },
      () => { }
    )
  })
}

axios.interceptors.response.use(
  response => Promise.resolve(response),
  error => {
    /** check for login errors */
    if (
      error.response.status === 401 &&
      error.response.data.error === 'invalid_credentials'
    ) {
      return Promise.reject(error)
    }
    /** if 401 force logout */
    if (error.response.status === 401) {
      window.bus.$emit('unauthenticated')
      return Promise.reject(error)
    }
    if (error.response.status === 403) {
      window.bus.$emit('unauthenticated')
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

// Vue.config.productionTip = false;
// Vue.use(VueDragDrop);
// Vue.use(ElementUI, { locale });

const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(PortalVue)

  .mount('#app')
