import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/404',
      name: '404',
      component: () =>
        import(/* webpackChunkName: '404' */ './views/PageNotFound.vue'),
    },
    {
      path: '/on-boarding',
      name: 'teacher-on-boarding',
      component: () =>
        import(
          /* webpackChunkName: 'teacher-on-boarding' */ './views/teacher/OnBoarding.vue'
        ),
    },
    {
      path: '/students/:id',
      name: 'student-show',
      component: () =>
        import(
          /* webpackChunkName: 'student-show' */ './views/students/Show.vue'
        ),
    },
    {
      path: '/profile/',
      name: 'teacher-profile',
      component: () =>
        import(
          /* webpackChunkName: 'teacher-profile' */ './views/teacher/Profile.vue'
        ),
    },
    {
      path: '/resources',
      name: 'resources',
      component: () =>
        import(
          /* webpackChunkName: 'resouces' */ './views/teacher/resources/Index.vue'
        ),
    },
    {
      path: '/resources/:id',
      name: 'resources-show',
      component: () =>
        import(
          /* webpackChunkName: 'teacher-profile' */ './views/teacher/resources/Show.vue'
        ),
    },
    {
      path: '/reports',
      props: true,
      name: 'reports',
      component: () =>
        import(/* webpackChunkName: 'students' */ './views/students/Index.vue'),
    },
    {
      path: '/reports/scores',
      props: true,
      name: 'reports-scores',
      component: () => import(/* webpackChunkName: 'students' */ './views/students/Index.vue'),
    },
    {
      path: '/reports/progress-overview',
      props: true,
      name: 'progress-overview',
      component: () =>
        import(
          /* webpackChunkName: 'reports-groups' */ './views/reports/ProgressOverviewReport.vue'
        ),
    },
    {
      path: '/reports/students',
      props: true,
      name: 'reports-student',
      component: () => import(/* webpackChunkName: 'students' */ './views/reports/StudentReport.vue'),
    },
    {
      path: '/reports/reading-fluency',
      props: true,
      name: 'reports-reading-fluency',
      component: () =>
        import(
          /* webpackChunkName: 'reports-groups' */ './views/reports/ReadingFluencyReport.vue'
        ),
    },
    {
      path: '/reports/early-reading/student/:studentId/module/:moduleId/session/:sessionId',
      props: true,
      name: 'review-early-reading',
      component: () =>
        import(
          /* webpackChunkName: 'review-early-reading' */ './views/review/EarlyReadingReview.vue'
        ),
    },
    {
      path: '/reports/math/student/:studentId/module/:moduleId/session/:sessionId',
      props: true,
      name: 'review-math',
      component: () =>
        import(
          /* webpackChunkName: 'review-math' */ './views/review/MathReview.vue'
        ),
    },
    {
      path: '/reports/reading-fluency/student/:studentId/module/:moduleId/session/:sessionId',
      props: true,
      name: 'review-reading-fluency',
      component: () =>
        import(
          /* webpackChunkName: 'review-reading-fluency' */ './views/review/ReadingFluencyReview.vue'
        ),
    },
    {
      path: '/redirect',
      props: true,
      name: 'redirect',
      component: () =>
        import(/* webpackChunkName: 'redirect' */ './views/Redirect.vue'),
    },
    {
      path: '/add-students',
      props: true,
      name: 'add-students',
      component: () =>
        import(
          /* webpackChunkName: 'add-students' */ './views/teacher/AddStudents.vue'
        ),
    },
    {
      path: '/students',
      props: true,
      name: 'students',
      component: () =>
        import(
          /* webpackChunkName: 'dashboard' */ './views/teacher/Dashboard.vue'
        ),
    },
    {
      path: '/groups',
      props: true,
      name: 'groups',
      component: () =>
        import(/* webpackChunkName: 'groups' */ './views/groups/Groups.vue'),
    },
    {
      path: '/groups/:groupId/students',
      props: true,
      name: 'group-students',
      component: () =>
        import(
          /* webpackChunkName: 'groups-students' */ './views/groups/GroupStudents.vue'
        ),
    },
    {
      path: '/groups/:groupId/modules',
      props: true,
      name: 'group-modules',
      component: () =>
        import(
          /* webpackChunkName: 'groups-modules' */ './views/groups/GroupModules.vue'
        ),
    },
    {
      path: '/c/:classroom/modules',
      props: true,
      name: 'classroom',
      component: () =>
        import(/* webpackChunkName: 'classroom' */ './views/Student.vue'),
    },
    {
      path: '/c/:classroom/s/:student',
      props: true,
      name: 'student',
      component: () =>
        import(/* webpackChunkName: 'student' */ './views/Student.vue'),
    },
    {
      path: '/c/:classroom/m/:module',
      props: true,
      name: 'student-math-module',
      component: () =>
        import(
          /* webpackChunkName: 'student-module' */ './views/StudentMathModule.vue'
        ),
    },
    {
      path: '/c/:classroom/rf/:module',
      props: true,
      name: 'student-reading-fluency-module',
      component: () =>
        import(
          /* webpackChunkName: 'student-reading-fluency-module' */ './views/StudentReadingFluencyModule.vue'
        ),
    },
    {
      path: '/c/:classroom/er/:module',
      props: true,
      name: 'student-early-reading-module',
      component: () =>
        import(
          /* webpackChunkName: 'student-early-reading-module' */ './views/StudentEarlyReadingModule.vue'
        ),
    },
    {
      path: '/c/:classroom/cat/:module',
      props: true,
      name: 'student-cat-module',
      component: () =>
        import(
          /* webpackChunkName: 'student-cat-module' */ './views/StudentCatModule.vue'
        ),
    },
    {
      path: '/student/profile',
      props: true,
      name: 'student-profile',
      component: () =>
        import(
          /* webpackChunkName: 'student-profile' */ './views/student/Profile.vue'
        ),
    },
  ],
})
