<template>
  <div class="h-screen print:hidden">
    <div class="flex flex-col w-full pb-16">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex flex-col flex-1 overflow-y-auto bg-white border-r border-gray-200"
      >
        <nav class="flex-1">
          <div class="p-2 border-b">
            <router-link
              to="/students"
              class="nav-item"
              active-class="is-active"
              @click="$emit('close-mobile-menu')"
            >
              <span class="material-icons"> people </span>
              Student Overview
            </router-link>
            <router-link
              v-if="false"
              to="/resources"
              class="nav-item"
              active-class="is-active"
              @click="$emit('close-mobile-menu')"
            >
              Resources
            </router-link>
            <router-link
              to="/groups"
              class="nav-item"
              active-class="is-active"
              @click="$emit('close-mobile-menu')"
            >
              <span class="material-icons"> group_work </span>
              Groups
            </router-link>
            <button
              type="button"
              class="w-full nav-item"
              aria-controls="sub-menu-1"
              :aria-expanded="reportDropdownOpen"
              @click="
                reportDropdownOpen
                  ? (reportDropdownOpen = null)
                  : (reportDropdownOpen = true)
              "
            >
              <span class="material-icons"> assessment </span>
              <span class="flex justify-between w-full">
                Reports
                <svg
                  :class="{ 'rotate-90': reportDropdownOpen }"
                  class="flex-shrink-0 w-5 h-5 mr-2 text-gray-600 transition-colors duration-150 ease-in-out transform group-hover:text-indigo-600"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path
                    d="M6 6L14 10L6 14V6Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
            <!-- Expandable link section, show/hide based on state. -->
            <div
              v-show="reportDropdownOpen"
              id="sub-menu-1"
              class="space-y-1"
            >
              <router-link
                :to="{ name: 'reports-scores' }"
                active-class="is-active"
                class="nav-sub-item"
                @click="$emit('close-mobile-menu')"
              >
                <span>Scores</span>
              </router-link>

              <router-link
                :to="{ name: 'progress-overview' }"
                active-class="is-active"
                class="nav-sub-item"
                @click="$emit('close-mobile-menu')"
              >
                Activity
              </router-link>

              <router-link
                :to="{ name: 'reports-student' }"
                active-class="is-active"
                class="nav-sub-item"
                @click="$emit('close-mobile-menu')"
              >
                Student
              </router-link>
            </div>
          </div>
          <div class="p-2 border-b">
            <router-link
              to="/profile"
              class="mb-0 nav-item"
              active-class="is-active"
              @click="$emit('close-mobile-menu')"
            >
              <span class="material-icons"> settings </span>
              Settings
            </router-link>
          </div>
        </nav>
        <div class="flex flex-shrink-0 p-4 border-t">
          <div class="flex flex-col items-center justify-center w-full">
            <p class="text-sm font-medium text-cool-gray-500">
              Questions? We want to help:
            </p>
            <a
              href="https://cbmskills.helpscoutdocs.com"
              target="_blank"
              class="flex items-center pt-1 text-sm font-medium text-indigo-500 transition duration-150 ease-in-out hover:text-indigo-700"
            >
              <span class="mr-1 text-sm material-icons"> support </span><span> Help Desk </span></a>
            <a
              href="mailto:support@cbmskills.com"
              class="flex items-center pt-1 text-sm font-medium text-indigo-500 transition duration-150 ease-in-out hover:text-indigo-700"
            >
              <span class="mr-1 text-sm material-icons"> mail </span><span> support@cbmskills.com </span></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  emits: ['close-mobile-menu'],
  data() {
    return {
      reportDropdownOpen: null,
      unreadMessages: null,
    }
  },
}
</script>
