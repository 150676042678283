<template>
  <span v-if="user" class="print:hidden">
    <div
      v-if="!user.email_verified"
      class="flex items-center justify-center gap-x-6 bg-indigo-600 px-6 py-1 sm:px-3.5 print:hidden"
    >
      <p class="text-sm leading-6 text-white">
        <strong class="mr-2 font-semibold text-center"
          >Please verify your email address</strong
        >
        <span v-if="verificationEmailResent"
          >verification email has been resent</span
        >
        <a
          v-else
          :class="{ 'is-loading': verificationEmailResent }"
          href="#"
          class="underline"
          @click.prevent="resendVerificationEmail"
          >resend verification email</a
        >
      </p>
    </div>
    <div
      :class="{ 'z-10': !sidebarToggled }"
      class="relative flex flex-shrink-0 h-16 bg-white border-b lg:px-4"
    >
      <button
        class="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:bg-gray-100 focus:text-gray-600 lg:hidden"
        aria-label="Open sidebar"
        @click.prevent="showMobileMenu = !showMobileMenu"
      >
        <svg
          class="w-6 h-6"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h7"
          />
        </svg>
      </button>
      <div class="flex justify-between flex-1 px-4">
        <div class="flex">
          <div class="flex items-center flex-shrink-0 mr-20">
            <img
              class="w-auto h-8"
              src="/images/logo/cbmskills-logo-text.svg"
              alt="CBMSkills"
            />
          </div>
        </div>
        <div class="flex items-center ml-4 md:ml-6">
          <!-- Profile dropdown -->
          <div v-click-away="closeDropdown" class="relative ml-3">
            <div v-if="user">
              <button
                id="user-menu"
                class="flex items-center justify-between max-w-xs px-2 py-1 text-sm rounded-full focus:outline-none focus:ring"
                aria-label="User menu"
                aria-haspopup="true"
                @click="openDropdown(!dropdown)"
              >
                <img
                  class="w-8 h-8 mr-2 rounded-full"
                  :src="user.avatar"
                  alt=""
                />
                <span class="hidden mr-1 text-base font-semibold md:block">{{
                  user.name
                }}</span>
                <span class="material-icons"> expand_more </span>
              </button>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <div
                v-show="dropdown"
                class="fixed absolute right-0 z-20 w-48 mt-2 origin-top-right rounded-md shadow-lg"
              >
                <div
                  class="py-1 text-left bg-white rounded-md ring-1 ring-black ring-opacity-5"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <span @click="dropdown = false">
                    <router-link
                      to="/profile"
                      class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                      role="menuitem"
                      >Settings</router-link
                    >
                  </span>
                  <span v-if="false" @click="dropdown = false">
                    <router-link
                      to="/profile/?action=feedback"
                      class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                      role="menuitem"
                      @click="dropdown = false"
                    >
                      Send feedback</router-link
                    >
                  </span>
                  <span @click="dropdown = false">
                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                      role="menuitem"
                      @click="openContactUsModal"
                    >
                      Contact us
                    </a>
                  </span>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100"
                    role="menuitem"
                    @click="logoutUser"
                  >
                    Sign out
                  </a>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
      <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
      <div v-show="showMobileMenu && user" class="lg:hidden">
        <div class="fixed inset-0 z-40 flex">
          <transition
            enter-class="transition-opacity duration-300 ease-linear"
            enter-active-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="transition-opacity duration-300 ease-linear"
            leave-active-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div v-show="showMobileMenu" class="fixed inset-0">
              <div class="absolute inset-0 bg-gray-600 opacity-75" />
            </div>
          </transition>
          <transition
            enter-class="transition duration-300 ease-in-out transform"
            enter-active-class="-translate-x-full"
            enter-to-class="translate-x-0"
            leave-class="transition duration-300 ease-in-out transform"
            leave-active-class="translate-x-0"
            leave-to-class="-translate-x-full"
          >
            <div
              v-show="showMobileMenu"
              class="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 transition duration-300 ease-in-out transform bg-white"
            >
              <div class="absolute top-0 right-0 p-1 -mr-14">
                <button
                  class="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
                  aria-label="Close sidebar"
                  @click="showMobileMenu = false"
                >
                  <svg
                    class="w-6 h-6 text-white"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div class="flex-1 h-0 mt-5 overflow-y-auto">
                <sidebar-tabs
                  v-if="user"
                  :user="user"
                  @close-mobile-menu="showMobileMenu = false"
                />
              </div>
            </div>
          </transition>
          <div class="flex-shrink-0 w-14">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </div>
      <contact-us-modal
        v-if="user"
        :user="user"
        :contact-us="contactUsModal"
        @close-contact="closeContactUsModal"
      />
    </div>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import get from 'lodash/get'
import { directive } from 'vue3-click-away'
import ContactUsModal from '../Modals/ContactUsModal.vue'
import SidebarTabs from '@/components/Students/SidebarTabs'
import axios from 'axios'

export default {
  components: { ContactUsModal, SidebarTabs },
  directives: {
    ClickAway: directive,
  },
  emits: ['selectGroup', 'toggleAddNewGroup', 'gradeSelect'],
  data() {
    return {
      showMobileMenu: false,
      dropdown: false,
      popoverGradeLevel: false,
      popover: false,
      editMode: false,
      groupName: '',
      name: '',
      contactUsModal: false,
      contactUsModalTransition: false,
      sendingStatus: false,
      sidebarToggled: false,
      verificationEmailResent: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'teacher/user',
    }),
    emptyState() {
      return this.queryBody == '' || this.fromEmail == ''
    },
    selectedGroupName() {
      return get(this.selectedGroup, 'name', '')
    },
  },
  watch: {
    selectedGroupName(current) {
      this.name = current
    },
    navbarDropdown(current) {
      if (!current) this.popover = current
    },
  },
  async created() {
    await this.fetchUser()
    this.fromEmail = this.user.email
  },
  async mounted() {
    window.bus.$on('sidebar-toggled', status => {
      this.sidebarToggled = status
    })

    window.addEventListener('click', e => {
      const element = document.getElementById('dropdown-menu-profile')
      if (element && !element.contains(e.target)) {
        if (this.dropdown) this.closeDropdown()
      }
    })
    this.groupName = this.selectedGroupName
    this.name = this.selectedGroupName
  },
  methods: {
    ...mapActions({
      logout: 'teacher/logout',
      updateGroupName: 'teacher/updateGroupName',
      fetchGroups: 'teacher/fetchGroups',
      fetchUser: 'teacher/fetchUser',
    }),
    resendVerificationEmail() {
      axios.get(`/email/resend`).then(() => {
        this.verificationEmailResent = true
      })
    },
    closeSideBar() {
      this.showMobileMenu = false
    },
    toggleGroupModal() {
      // eslint-disable-next-line vue/custom-event-name-casing
      window.bus.$emit('toggleAddNewGroup')
    },
    openContactUsModal() {
      this.contactUsModal = true
      this.dropdown = false
    },
    closeContactUsModal() {
      this.contactUsModal = false
    },
    async logoutUser() {
      setTimeout(async () => {
        await this.logout()
        localStorage.removeItem('auth')
        location.replace(`${process.env.VUE_APP_BASE_URL}`)
      }, 1500)
    },
    async updateName() {
      await this.updateGroupName({
        groupId: this.selectedGroup.id,
        groupName: this.groupName,
      })
      await this.fetchGroups()
      this.name = this.groupName
      this.editMode = false
    },
    openDropdown(status) {
      if (!status) {
        this.closeDropdown()

        return
      }

      this.dropdown = true
    },
    select(id) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('selectGroup', id)
    },
    selectGrade(id) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('gradeSelect', id)
    },
    closeDropdown() {
      this.dropdown = false
    },
  },
}
</script>
