<template>
  <div id="app">
    <div v-if="!teacherMode">
      <portal-target name="modal" @change="showModal = $event">
        <!--
        This component can be located anywhere in your App.
        The slot content of the above portal component will be rendered here.
      -->
      </portal-target>

      <!-- <vue-snotify></vue-snotify> -->
      <banner :show="showBanner" />
      <app-header v-if="routeName !== 'teacher-on-boarding'" />
      <router-view ref="routerView" />
      <app-footer v-if="routeName !== 'teacher-on-boarding'" />
    </div>
    <div
      v-else
      class="h-screen print:!h-full print:!overflow-auto overflow-hidden"
    >
      <!-- <vue-snotify></vue-snotify> -->
      <portal-target name="modal" @change="showModal = $event">
        <!--
        This component can be located anywhere in your App.
        The slot content of the above portal component will be rendered here.
      -->
      </portal-target>
      <portal-target name="modal-info" @change="showModal = $event" />
      <teacher-navbar />
      <div
        class="flex h-screen print:!h-full print:!overflow-auto overflow-hidden"
      >
        <sidebar-tabs
          v-if="user"
          :user="user"
          class="hidden w-64 lg:flex lg:flex-shrink-0"
        />
        <div
          class="flex flex-col flex-1 w-0"
          :class="[
            ['groups'].includes(routeName) ? 'bg-gray-100' : '',
            ['review-reading-fluency'].includes(routeName)
              ? ''
              : 'overflow-y-auto',
          ]"
        >
          <router-view />
        </div>
      </div>
    </div>
    <notification />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TeacherNavbar from '@/components/Students/TeacherNavbar'
import SidebarTabs from '@/components/Students/SidebarTabs'
import AppHeader from './components/AppHeader.vue'
import Notification from './components/Notification.vue'
import Banner from '@/components/Banner'
import AppFooter from '@/components/AppFooter'

export default {
  components: {
    TeacherNavbar,
    SidebarTabs,
    Banner,
    AppFooter,
    AppHeader,
    Notification,
  },
  data() {
    return {
      showCreateClassModal: false,
      showModal: false,
      routeName: '',
      teacherRoutes: [
        'on-boarding',
        'reports-student',
        'reports-scores',
        'progress-overview',
        'reports-reading-fluency',
        'review-early-reading',
        'review-math',
        'review-reading-fluency',
        'reports',
        'teacher-profile',
        'student-show',
        'resources',
        'resources-show',
        'students',
        'add-students',
        'groups',
        'group-students',
        'group-modules',
        'group',
      ],
    }
  },
  computed: {
    ...mapGetters({
      showBanner: 'banner/showBanner',
      user: 'teacher/user',
      groups: 'teacher/groups',
      classroom: 'teacher/classroom',
    }),
    teacherMode() {
      return this.teacherRoutes.includes(this.routeName)
    },
  },
  watch: {
    $route(current) {
      this.routeName = current.name
    },
    groups: {
      handler() {
        if (!this.classroom) {
          this.showCreateClassModal = true
        }
      },
      deep: true,
    },
  },
  async created() {
    if (!this.user) {
      return false
    }
    if (this.user.role === 'teacher') {
      this.loading = true
      await this.fetchUser()
      await this.fetchGroups('grade_level_id')
      if (!this.groups.length) {
        this.showCreateClassModal = true
      }
      this.loading = false
    }
  },
  beforeCreate() {
    /** unauthenticated */
    window.bus.$on('unauthenticated', () => {
      this.unauthenticated()
    })
  },
  beforeUnmount() {
    window.bus.$off('unauthenticated')
  },
  methods: {
    ...mapActions({
      setBannerStatus: 'banner/setBannerStatus',
      fetchUser: 'teacher/fetchUser',
      fetchGroups: 'teacher/fetchGroups',
      updateDefaultGradeLevel: 'teacher/updateDefaultGradeLevel',
      logout: 'teacher/logout',
    }),
    unauthenticated() {
      setTimeout(async () => {
        await this.$store.dispatch('teacher/logout')
        location.replace(`${process.env.VUE_APP_BASE_URL}`)
      }, 250)
    },
    logoutUser() {
      this.logout()
    },
  },
}
</script>
<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.blur {
  filter: blur(9px);
  -webkit-filter: blur(9px);
  -moz-filter: blur(9px);
  -o-filter: blur(9px);
  -ms-filter: blur(9px);
}
</style>
