<template>
  <div v-if="!isFullScreen" class="p-2 mb-8 border-b">
    <div
      v-if="studentClassroom"
      class="container flex items-center justify-between mx-auto"
    >
      <router-link
        :to="{ name: 'classroom', params: { classroom: studentClassroom } }"
      >
        <img
          src="/images/static/cbms-mascot-round.svg"
          alt="Welcome"
          class="h-12"
        />
      </router-link>
      <div
        v-if="
          user &&
          !authStudent &&
          ($route.name === 'student' || $route.name === 'classroom') &&
          hasBothModuleTypes
        "
      >
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <select
            id="tabs"
            v-model="navSelectedTab"
            name="tabs"
            class="block w-full border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
            @change="updateModuleType(navSelectedTab)"
          >
            <option
              v-for="tab in tabs"
              :key="tab.name"
              :value="tab.index"
              :selected="tab.index === moduleType"
            >
              {{ tab.name }}
            </option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav class="flex space-x-4" aria-label="Tabs">
            <a
              v-for="tab in tabs"
              :key="tab.name"
              :href="tab.href"
              :class="[
                moduleType === tab.index
                  ? 'font-bold text-indigo-700'
                  : 'text-indigo-400 hover:text-indigo-700  hover:font-bold',
                'px-3 py-2 font-medium text-sm rounded-md cursor-pointer',
              ]"
              :aria-current="moduleType === tab.index ? 'page' : undefined"
              @click.prevent="updateModuleType(tab.index)"
            >
              <div class="flex flex-col">
                <span class="text-4xl material-icons"> {{ tab.icon }} </span>
                <span class="text-sm uppercase hover:font-bold">
                  {{ tab.name }}
                </span>
              </div>
            </a>
          </nav>
        </div>
      </div>

      <div
        v-if="user && !authStudent"
        v-click-away="closeDropdownProfile"
        class="relative inline-block text-left"
      >
        <div>
          <span class="rounded-md shadow-sm">
            <button
              id="options-menu"
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue active:bg-gray-50 active:text-gray-800"
              aria-haspopup="true"
              aria-expanded="true"
              @click="dropdownProfile = !dropdownProfile"
            >
              {{ trans('Hi') }}, {{ user.name }}
              <svg
                class="w-5 h-5 ml-2 -mr-1"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </span>
        </div>
        <transition
          enter-active-class="transition duration-100 ease-out"
          enter-class="transform scale-95 opacity-0"
          enter-to-class="transform scale-100 opacity-100"
          leave-active-class="transition duration-75 ease-in"
          leave-class="transform scale-100 opacity-100"
          leave-to-class="transform scale-95 opacity-0"
        >
          <div
            v-show="dropdownProfile"
            class="absolute right-0 z-10 w-56 mt-2 origin-top-right rounded-md shadow-lg"
          >
            <div
              class="bg-white rounded-md ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div class="px-4 py-3">
                <p class="text-sm font-medium leading-5">
                  {{ trans('Signed in as') }}
                </p>
                <p class="text-sm font-bold leading-5 text-gray-900 truncate">
                  {{ user.username }}
                </p>
              </div>
              <div class="border-t border-gray-100" />
              <div class="py-1">
                <router-link
                  :to="{ name: 'student-profile' }"
                  class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                >
                  {{ trans('Profile') }}
                </router-link>
              </div>
              <div class="border-t border-gray-100" />
              <!-- <div class="py-1">
                <a
                  href="#"
                  class="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                  @click="openContactUsModal"
                >
                  {{ trans('Contact us') }}
                </a>
              </div> -->
              <div class="border-t border-gray-100" />
              <div class="py-1">
                <button
                  type="button"
                  class="block w-full px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem"
                  @click="logoutUser"
                >
                  {{ trans('Sign out') }}
                </button>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- </div> -->
    </div>
    <contact-us-modal
      v-if="user"
      :user="user"
      :contact-us="contactUsModal"
      @close-contact="closeContactModal"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import trans from '@/utils/trans'
import get from 'lodash/get'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import { directive } from 'vue3-click-away'
import ContactUsModal from './Modals/ContactUsModal.vue'
export default {
  components: {
    ContactUsModal,
  },
  directives: {
    ClickAway: directive,
  },
  data() {
    return {
      navSelectedTab: 'math',
      authStudent: false,
      dropdown: false,
      dropdownProfile: false,
      contactUsModal: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'student/student',
      moduleType: 'student/moduleType',
      modules: 'student/modules',
      isFullScreen: 'student/isFullScreen',
    }),
    tabs() {
      return [
        { name: trans('Math'), icon: 'calculate', index: 'math' },
        { name: trans('Reading'), icon: 'library_books', index: 'reading' },
      ]
    },
    isDefaultClassroom() {
      return get(this.user, 'is_default_classroom')
    },
    notifications() {
      if (this.user === null) {
        return []
      }

      if (this.isDefaultClassroom) {
        return []
      }

      let notifications = get(this.user, 'user.notifications')
      notifications = [...notifications]

      if (!get(this.user, 'user.is_changed_password')) {
        const notification = {
          id: `notif-${this.user.id}`,
          data: {
            type: 'password',
            link: 'profile',
            body: this.trans('Change your password.'),
          },
        }
        notifications.push(notification)
      }

      return notifications
    },
    studentClassroom() {
      return get(this.user, 'classroom.slug')
    },
    isEmptyNotifications() {
      return isEmpty(this.notifications)
    },
    hasBothModuleTypes() {
      if (!find(this.modules, { type: 'math' })) {
        return false
      }
      if (
        !find(this.modules, { type: 'early-reading' }) &&
        !find(this.modules, { type: 'reading-fluency' })
      ) {
        return false
      }
      return true
    },
  },
  created() {
    window.bus.$on('studentLoggedIn', async () => {
      try {
        await this.fetchUser()
      } catch (err) {
        this.authStudent = true
      }
    })
  },
  methods: {
    ...mapActions({
      fetchUser: 'student/fetchUser',
      updateModuleType: 'student/updateModuleType',
      logout: 'teacher/logout',
    }),
    closeContactModal(status) {
      this.contactUsModal = status
    },
    openContactUsModal() {
      this.contactUsModal = true
    },
    toggleDropdown() {
      if (!this.dropdown) {
        this.dropdown = true
      } else {
        this.closeDropdown()
      }
    },
    closeDropdown() {
      this.dropdown = false
    },
    redirectToProfile() {
      this.$router.push({ name: 'student-profile' })
    },
    closeDropdownProfile() {
      this.dropdownProfile = false
    },
    logoutUser() {
      setTimeout(async () => {
        await this.logout()
        location.replace(`${process.env.VUE_APP_BASE_URL}`)
      }, 1500)
    },
    trans,
  },
}
</script>
