<template>
  <div v-if="moduleType !== 'reading-fluency'">
    <div class="text-left">
      <label
        for="location"
        class="block text-sm font-medium text-gray-700"
      >Select language</label>
      <select
        v-if="languages"
        id="location"
        v-model="groupLanguage"
        class="block w-full py-2 pl-3 pr-10 mt-1 text-base border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        @change="changeLanguage"
      >
        <option
          v-for="(language, index) in languages"
          :key="index"
        >
          {{ language.name }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import find from 'lodash/find'

export default {
  data() {
    return {
      load: false,
      groupLanguage: '',
    }
  },
  computed: {
    ...mapGetters({
      languages: 'student/languages',
      currentLanguage: 'student/language',
      student: 'student/student',
      moduleType: 'student/moduleType',
    }),
  },

  watch: {
    currentLanguage() {
      this.fetchTexts()
    },
  },

  async mounted() {
    await this.fetchLanguages()
    // await this.fetchUser();
    this.groupLanguage = this.currentLanguage
      ? this.currentLanguage.name
      : 'English'
  },
  methods: {
    ...mapActions({
      setLanguage: 'student/setLanguage',
      fetchLanguages: 'student/fetchLanguages',
      fetchModules: 'student/fetchModules',
      fetchTexts: 'language/fetchTexts',
      fetchUser: 'student/fetchUser',
    }),

    async changeLanguage() {
      this.load = true
      let language = find(this.languages, { name: this.groupLanguage })

      await this.setLanguage(language.slug)
      await this.fetchModules()
      this.load = false
    },
  },
}
</script>

<style>
.dp-class-bp__btn {
  border: none;
}
.dp-class-bp__btn svg {
  display: none;
}

.dp-class-bp__body {
  top: 511px;
  padding: 0;
}

.bp-dropdown--sub {
  width: 100%;
}

.bp-dropdown--sub .bp-dropdown__btn,
.bp-dropdown--sub .bp-dropdown__sub {
  width: 100%;
}

.bp-dropdown--sub .bp-dropdown__icon {
  margin-left: auto;
}

.bp-dropdown__btn {
  display: inline-flex;
  align-items: center;
  padding: 3px 5px;
  border: 1px solid #efefef;
  cursor: pointer;
  transition: background-color 0.1s ease;
}

.bp-dropdown__sub {
  display: inline-flex;
  align-items: center;
}

.bp-dropdown__btn--active {
  background-color: #eee;
}

.bp-dropdown__icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  overflow: visible;
  transition: transform 0.1s ease;
}

.bp-dropdown__icon--spin {
  width: 12px;
  height: 12px;
  animation: spin 2s infinite linear;
}

.bp-dropdown__icon--top {
  transform: rotate(-180deg);
}

.bp-dropdown__icon--right {
  transform: rotate(-90deg);
}

.bp-dropdown__icon--bottom {
  transform: rotate(0);
}

.bp-dropdown__icon--left {
  transform: rotate(-270deg);
}

.bp-dropdown__btn--active .bp-dropdown__icon--top,
.bp-dropdown__sub--active .bp-dropdown__icon--top {
  transform: rotate(0);
}

.bp-dropdown__btn--active .bp-dropdown__icon--right,
.bp-dropdown__sub--active .bp-dropdown__icon--right {
  transform: rotate(-270deg);
}

.bp-dropdown__btn--active .bp-dropdown__icon--bottom,
.bp-dropdown__sub--active .bp-dropdown__icon--bottom {
  transform: rotate(-180deg);
}

.bp-dropdown__btn--active .bp-dropdown__icon--left,
.bp-dropdown__sub--active .bp-dropdown__icon--left {
  transform: rotate(-90deg);
}

.bp-dropdown__body {
  position: fixed;
  top: 0;
  left: 0;
  padding: 6px 8px;
  background-color: #fff;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tooltip .tooltip-text {
  visibility: hidden;
  text-align: center;
  padding: 2px 6px;
  position: absolute;
  z-index: 100;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
</style>
