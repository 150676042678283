/* eslint-disable no-unused-vars */
import axios from 'axios'
import router from '@/router'

export const state = {
  user: null,
  students: null,
  studentStats: null,
  studentSessions: null,
  classroom: null,
  student: null,
  classrooms: null,
  gradeLevels: null,
  modules: null,
  groups: [],
  group: null,
  modulesWithResults: null,
  feedback: null,
  googleClassrooms: [],
  moduleItems: null,
  moduleMeta: null,
  moduleLinks: null,
}

export const getters = {
  user: state => state.user,
  isSubscribed: state => state.user.is_subscribed,
  classrooms: state => state.classrooms,
  googleClassrooms: state => state.googleClassrooms,
  googleStudents: state => state.googleStudents,
  classroom: state => state.classroom,
  students: state => state.students,
  studentStats: state => state.studentStats,
  studentSessions: state => state.studentSessions,
  student: state => state.student,
  gradeLevels: state => state.gradeLevels,
  modules: state => state.modules,
  moduleItems: state => state.moduleItems,
  moduleMeta: state => state.moduleMeta,
  moduleLinks: state => state.moduleLinks,
  groups: state => state.groups,
  group: state => state.group,
  mathGroups: state => {
    if (!state.groups) {
      return []
    }
    return state.groups.filter(group => {
      return group.type === 'math'
    })
  },
  readingFluencyGroups: state => {
    if (!state.groups) {
      return []
    }
    return state.groups.filter(group => {
      return group.type === 'reading-fluency'
    })
  },
  earlyReadingGroups: state => {
    if (!state.groups) {
      return []
    }
    return state.groups.filter(group => {
      return group.type === 'early-reading'
    })
  },
  modulesWithResults: state => state.modulesWithResults,
  feedback: state => state.feedback,
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user
  },

  SET_CLASSROOMS(state, classrooms) {
    state.classrooms = classrooms
  },

  SET_CLASSROOM(state, classroom) {
    state.classroom = classroom
  },

  SET_STUDENTS(state, students) {
    state.students = students
  },

  SET_STUDENTS_STATS(state, stats) {
    state.studentStats = stats
  },

  SET_STUDENTS_SESSIONS(state, sessions) {
    state.studentSessions = sessions
  },

  SET_STUDENT(state, student) {
    state.student = student
  },

  SET_GRADE_LEVELS(state, gradeLevels) {
    state.gradeLevels = gradeLevels
  },

  SET_MODULES(state, modules) {
    state.modules = modules
  },
  SET_MODULE_ITEMS(state, moduleItems) {
    state.moduleItems = moduleItems
  },
  SET_MODULE_ITEMS_META(state, moduleMeta) {
    state.moduleMeta = moduleMeta
  },
  SET_MODULE_ITEMS_LINKS(state, moduleLinks) {
    state.moduleLinks = moduleLinks
  },
  SET_GROUPS(state, groups) {
    state.groups = groups
    if (!state.group) {
      state.group = groups[0]
    }
  },

  SET_GROUP(state, group) {
    state.group = group
  },

  SET_MODULES_WITH_RESULTS(state, modulesWithResults) {
    state.modulesWithResults = modulesWithResults
  },

  SET_GOOGLE_CLASSROOMS(state, classrooms) {
    state.googleClassrooms = classrooms
  },
}

export const actions = {
  async fetchUser({ commit, dispatch }) {
    const { data } = await axios.get('me')

    commit('SET_USER', data.data)
    commit('SET_CLASSROOM', data.data.classroom)
  },

  async deleteUser({ commit, dispatch }, password) {
    const { data } = await axios.post('/teacher/delete', {
      password,
    })
  },

  async logout({ commit, dispatch }) {
    axios.defaults.baseURL = '/'
    localStorage.clear()
    try {
      const { data, status } = await axios.post('logout')
    } catch (err) {
      location.replace(`${process.env.VUE_APP_BASE_URL}`)
    }
  },

  async submitOnBoarding({ commit }, data) {
    await axios.post('/teacher/on-boarding', data)
  },

  async assistStudent({ commit }, { questionId, signedParams }) {
    return axios.get(`questions/${questionId}/assistance`, {
      params: signedParams,
    })
  },

  async importFromGoogle({ commit }) {
    const {
      data: { data, redirect },
    } = await axios.get(`classroom/import`)

    if (redirect) {
      window.location.href = redirect
    }

    commit('SET_GOOGLE_CLASSROOMS', data)
  },

  async getStudentsFromGoogleClassrooms({ commit }, { courseId }) {
    const { data } = await axios.get(`classroom/import/${courseId}`)

    return data.data.students
  },

  async updateDrawing(
    { getters },
    { questionId, drawing, sessionId, socketId, userId, isReview }
  ) {
    await axios.put(
      `questions/${questionId}/assistance`,
      {
        drawing: drawing,
        session_id: sessionId,
        user_id: userId,
        is_review: isReview,
      },
      {
        headers: {
          'X-Socket-ID': socketId,
        },
      }
    )
  },

  async fetchStudents({ commit }, rules) {
    await axios
      .get('user/students', {
        params: {
          include: rules.includes,
          rules: rules.rules,
        },
      })
      .then(async ({ data }) => {
        commit('SET_STUDENTS', data.data)
      })
      .catch(() => {})
  },

  async fetchStudentsStats({ commit, state }, studentIds) {
    const { data } = await axios.get(`student/stats`, {
      params: {
        students: studentIds,
        group_id: state.group.id,
      },
    })
    commit('SET_STUDENTS_STATS', data.data)
  },

  async fetchStudentsSessions({ commit }, studentIds) {
    const { data } = await axios.get(`student/sessions`, {
      params: {
        students: studentIds,
        from_review: true,
      },
    })

    commit('SET_STUDENTS_SESSIONS', data.data)
  },

  async fetchGroups({ commit }, orderBy = null) {
    const { data } = await axios.get(`student/groups`, {
      params: {
        language: null,
      },
    })
    commit('SET_GROUPS', data.data)
  },
  async fetchTeacherGroups({ commit }) {
    const { data } = await axios.get(`groups`)

    commit('SET_GROUPS', data.data)
  },
  async fetchGroup({ commit }, groupId) {
    const { data } = await axios.get(`student/groups/${groupId}`, {
      params: {
        language: null,
      },
    })
    commit('SET_GROUP', data.data)
  },
  async setGroup({ commit }, group) {
    commit('SET_GROUP', group)
  },
  async toggleModuleVisibility({ commit }, { moduleId, groupId, hidden }) {
    const { data } = await axios.patch(`student/groups/${groupId}`, {
      module_id: moduleId,
      hidden,
    })

    commit('SET_GROUP', data.data)
  },
  async deleteSingleModule(
    { commit },
    { groupId, moduleId, deleteSingleModule }
  ) {
    const { data } = await axios.delete(`student/groups/${groupId}`, {
      params: {
        modules: moduleId.toString().split(),
        delete_single_module: deleteSingleModule,
      },
    })
  },

  async sendContactQuery({ commit }, { email, body }) {
    const { data } = await axios.post(`user/contact`, {
      email,
      body,
    })
  },

  async updateGroupModules({ commit }, { groupId, moduleIds }) {
    const { data } = await axios.patch(`student/groups/${groupId}`, {
      modules: moduleIds,
    })

    commit('SET_GROUP', data.data)
  },

  async updateModuleOrder({ commit }, { groupId, newModule }) {
    const { data } = await axios.patch(`student/groups/${groupId}`, {
      module_orders: newModule,
    })
  },

  async updateGroupStudents({ commit }, { groupId, studentIds }) {
    const { data } = await axios.patch(`student/group/${groupId}/bulk`, {
      students: studentIds,
      from_students: true,
    })
  },

  async updateGroupName({ commit }, { groupId, groupName }) {
    const { data } = await axios.patch(`student/groups/${groupId}`, {
      name: groupName,
    })

    return data.data
  },

  async deleteGroup({ commit }, groupId) {
    const { data } = await axios.delete(`student/groups/${groupId}`)
  },

  async addGroup(
    { commit },
    { groupName, moduleIds, studentIds, gradeLevelId, groupType }
  ) {
    const { data } = await axios.post(`student/groups/`, {
      name: groupName,
      modules: moduleIds,
      students: studentIds,
      type: groupType,
      grade_level_id: gradeLevelId,
    })

    commit('SET_GROUP', data.data)

    return data.data
  },

  async addStudents({ commit, getters }, { students }) {
    const { data } = await axios.post('user/students', {
      students,
      classroom: getters.classroom,
    })

    return data.data
  },

  async fetchClassrooms({ commit }) {
    const { data } = await axios.get('teacher/classrooms')

    commit('SET_CLASSROOMS', data.data)
  },

  async reviewSession({ commit }, { sessionId, questionId }) {
    return await axios.post(`sessions/${sessionId}/review`, {
      question: questionId,
      session_id: sessionId,
    })
  },

  async fetchStudent({ commit }, studentId) {
    const { data } = await axios.get(`user/students/${studentId}`)

    commit('SET_STUDENT', data.data)
  },

  async setStudentLanguage({ commit, getters }, { language, classroom }) {
    const { data } = await axios.patch(`students/${getters.student.id}`, {
      classroom: classroom.slug,
      language,
    })

    commit('SET_STUDENT', data.data)
  },
  async fetchGradeLevels({ commit }) {
    const { data } = await axios.get(`gradeLevels`)

    commit('SET_GRADE_LEVELS', data.data)
  },

  async uploadStudentCsv({ commit }, formData) {
    const { data } = await axios.post('user/students', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return data.data
  },

  async updateGradeLevel({ commit }, { name, studentId, gradeLevelId }) {
    const { data } = await axios.patch(`user/students/${studentId}`, {
      name,
      grade_level_id: gradeLevelId,
    })

    commit('SET_STUDENT', data.data)
  },

  async updateTeacher({ commit }, { teacherId, updatedData }) {
    return new Promise((resolve, reject) => {
      axios.post(`teacher/${teacherId}/update/`, updatedData).then(
        response => {
          commit('SET_USER', response.data.data)
          return resolve(response)
        },
        error => {
          return reject(error)
        }
      )
    })
  },

  async changePassword({ commit }, passwordData) {
    return new Promise((resolve, reject) => {
      axios.patch(`change-password`, passwordData).then(
        response => {
          return resolve(response)
        },
        error => {
          return reject(error)
        }
      )
    })
  },

  async updateStudentPassword(
    { commit },
    { password, password_confirmation, studentId }
  ) {
    const { data } = await axios.patch(`user/students/${studentId}`, {
      password,
      password_confirmation,
    })
  },

  async updateStudentName({ commit }, { name, studentId }) {
    const { data } = await axios.patch(`user/students/${studentId}`, {
      name,
    })

    commit('SET_STUDENT', data.data)
  },

  async deleteStudent({ commit }, { studentId }) {
    const { data } = await axios.delete(`user/students/${studentId}`)
  },

  async deleteAllStudents() {
    const { data } = await axios.delete(`user/students/all`)
  },

  async updateClassroom({ commit }, { classroomId, classroomData }) {
    return new Promise((resolve, reject) => {
      axios.patch(`teacher/classrooms/${classroomId}`, classroomData).then(
        response => {
          return resolve(response)
        },
        error => {
          return reject(error)
        }
      )
    })
  },

  async sendFeedback({ commit }, { type, description }) {
    const { data } = await axios.post(`feedbacks`, {
      type,
      description,
    })
  },

  async updateDefaultGradeLevel({ commit }, { gradeLevel, classroom }) {
    const { data } = await axios.patch(`teacher/classrooms/${classroom}`, {
      grade_level_id: gradeLevel,
    })

    commit('SET_CLASSROOM', data.data)
  },

  setModulesWithResults({ commit }, modules) {
    commit('SET_MODULES_WITH_RESULTS', modules)
  },

  async exportStudents({ dispatch }, rules) {
    await axios
      .post(
        'students/export',
        { rules },
        {
          responseType: 'blob',
        }
      )
      .then(res => dispatch('forceFileDownload', res))
  },

  async downloadTemplate({ dispatch }, rules) {
    await axios
      .post(
        'export/template',
        { rules },
        {
          responseType: 'blob',
        }
      )
      .then(res => dispatch('forceFileDownload', res))
  },

  forceFileDownload({ commit }, response) {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    const contentDisposition = response.headers['content-disposition']
    let filename = ''
    if (contentDisposition) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
      var matches = filenameRegex.exec(contentDisposition)
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '')
      }
    }
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  },

  async updateReview({ commit }, questionId) {
    return await axios.put(`questions/${questionId}/assistance`, {
      done_review: true,
    })
  },

  async fetchModules({ commit }) {
    const { data } = await axios.get(`all-modules`)

    commit('SET_MODULES', data.data)
  },
  async fetchModulesByType({ commit }, { type }) {
    const { data } = await axios.get(`all-modules?type=${type}`)
    commit('SET_MODULES', data.data)
  },
  async fetchModuleItems({ commit }, { moduleId, page }) {
    const { data } = await axios.get(`module-items`, {
      params: {
        language: null,
        module_id: moduleId,
        page,
      },
    })

    commit('SET_MODULE_ITEMS', data.data)
    commit('SET_MODULE_ITEMS_META', data.meta)
    commit('SET_MODULE_ITEMS_LINKS', data.links)
  },
}
