import axios from 'axios'
import router from '@/router'
export const state = {
  data: null,
}

export const getters = {
  data: state => state.data,
}

export const mutations = {
  SET_DATA(state, data) {
    state.data = data
  },
}

export const actions = {
  async fetchTexts({ commit, rootGetters }) {
    let classroom = rootGetters['student/slug']
    let student = rootGetters['student/student']
    let passcode = rootGetters['student/passcode']

    if (!student) {
      const { data } = await axios.get(`/teacher/language`, {
        params: {
          language: router.currentRoute.query.lang,
        },
      })
      commit('SET_DATA', data.texts)
    } else {
      const { data } = await axios.get('/languages/file', {
        params: {
          student_id: student ? student.id : null,
          classroom,
          passcode,
        },
      })
      commit('SET_DATA', data.texts)
    }
  },
}
