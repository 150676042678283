<template>
  <div
    v-if="!isFullScreen"
    class="bg-white"
  >
    <div
      class="max-w-screen-xl px-4 py-12 mx-auto sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
    >
      <div class="flex items-center justify-center md:order-2">
        <span class="ml-6">
          <country-dropdown v-if="moduleType === 'math'" />
        </span>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-sm leading-6 text-center text-gray-600 lg:text-base">
          &copy; 2020 CBMSkills. {{ trans('All rights reserved') }}.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CountryDropdown from './CountryDropdown'
import trans from '@/utils/trans'
import { mapGetters } from 'vuex'

export default {
  components: { CountryDropdown },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    ...mapGetters({
      isFullScreen: 'student/isFullScreen',
      moduleType: 'student/moduleType',
    }),
  },
  methods: {
    trans,
  },
}
</script>
