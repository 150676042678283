<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6"
  >
    <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="w-full max-w-md overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start items-center">
              <div class="flex flex-shrink-0 items-cetner">
                <span
                  v-if="type == 'error'"
                  class="w-6 h-6 text-left text-red-700 material-icons"
                  aria-hidden="true"
                >
                  cancel
                </span>
                <span
                  v-else
                  class="w-6 h-6 text-left text-green-700 material-icons"
                  aria-hidden="true"
                >
                  check_circle
                </span>
                <!-- <CheckCircleIcon class="" /> -->
              </div>
              <div class="flex-1 w-0 ml-3">
                <p class="text-sm font-medium text-left text-gray-900">
                  {{ message }}
                </p>
                <!-- <p class="mt-1 text-sm text-gray-500">Anyone with a link can now view this file.</p> -->
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  type="button"
                  class="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="show = false"
                >
                  <span class="sr-only">Close</span>
                  <span class="material-icons" aria-hidden="true"> close </span>
                  <!-- <XMarkIcon class="w-5 h-5" > -->
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      show: false,
      type: null,
      message: null,
    }
  },
  mounted() {
    window.bus.$on('notification', (type, message) => {
      this.type = type
      this.message = message
      this.show = true
      setTimeout(() => {
        this.show = false
        this.type = null
        this.message = null
      }, 4000)
    })
  },
}
</script>
