<template>
  <div
    v-show="contactUsModalTransition"
    class="fixed inset-0 z-10 overflow-y-auto"
  >
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <transition
        enter-active-class="duration-300 ease-out"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        @before-enter="contactUsModalTransition = true"
        @after-leave="contactUsModalTransition = false"
      >
        <div
          v-show="contactUsModal"
          class="fixed inset-0 transition-opacity"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />&#8203;
      <transition
        enter-active-class="duration-300 ease-out"
        enter-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
        enter-to-class="translate-y-0 opacity-100 sm:scale-100"
        leave-active-class="duration-200 ease-in"
        leave-class="translate-y-0 opacity-100 sm:scale-100"
        leave-to-class="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
      >
        <div
          v-show="contactUsModal"
          class="inline-block overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div
                class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-gray-100 rounded-full sm:mx-0 sm:h-10 sm:w-10"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  id="modal-headline"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Have any questions/suggestions about CBMSkills?
                </h3>
                <div class="mt-6">
                  <p class="text-sm leading-5 text-gray-500">
                    Please contact us for either help or improvements.
                  </p>
                </div>
                <div>
                  <div class="relative mt-4 rounded-md shadow-sm">
                    <input
                      v-model="fromEmail"
                      type="email"
                      class="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm sm:leading-5 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="What's your email?"
                    >
                  </div>
                </div>
                <div class="mt-2 mb-4">
                  <div class="relative mt-1 rounded-md shadow-sm">
                    <textarea
                      v-model="queryBody"
                      cols="20"
                      rows="5"
                      class="block w-full border-gray-300 rounded-md shadow-sm sm:text-sm sm:leading-5 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                      placeholder="Description of help or improvement"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 py-3 bg-gray-50 sm:px-6 sm:flex sm:flex-row-reverse">
            <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
              <button
                type="button"
                :disabled="sendingStatus || emptyState"
                class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md shadow-sm disabled:cursor-not-allowed disabled:opacity-50 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:ring-indigo sm:text-sm sm:leading-5"
                @click="sendQuery"
              >
                {{ sendText }}
              </button>
            </span>
            <span
              class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto"
            >
              >
              <button
                type="button"
                class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue sm:text-sm sm:leading-5"
                @click="closeContactUsModal"
              >
                Cancel
              </button>
            </span>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    contactUs: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  emits: ['close-contact'],
  data() {
    return {
      contactUsModal: false,
      contactUsModalTransition: false,
      queryBody: '',
      fromEmail: '',
      sendText: 'Send',
      sendingStatus: false,
    }
  },
  computed: {
    emptyState() {
      return this.queryBody == '' || this.fromEmail == ''
    },
  },
  watch: {
    contactUs(current) {
      this.contactUsModal = current
    },
  },
  mounted() {
    this.fromEmail = this.user.email || this.user.username
  },
  methods: {
    ...mapActions({
      sendContactQuery: 'teacher/sendContactQuery',
    }),
    closeContactUsModal() {
      this.queryBody = ''
      this.fromEmail = ''
      this.$emit('close-contact', false)
    },
    async sendQuery() {
      this.sendText = 'Sending...'
      this.sendingStatus = true
      await this.sendContactQuery({
        email: this.fromEmail,
        body: this.queryBody,
      })
      window.bus.$emit('notification', 'success', 'Successfully sent!')
      this.sendText = 'Send'
      this.sendingStatus = false
      this.closeContactUsModal()
    },
  },
}
</script>
