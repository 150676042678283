/* eslint-disable no-unused-vars */
import assign from 'lodash/assign'
import axios from 'axios'
import Cookies from 'js-cookie'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import isEqual from 'lodash/isEqual'
import head from 'lodash/head'
import map from 'lodash/map'
import get from 'lodash/get'
import checkAnswer from '@/utils/checkAnswer'
import decodeAnswer from '@/utils/decodeAnswer'
import cacheAssets from '@/utils/cacheAssets'
import generateTimestamp from '@/utils/generateTimestamp'

export const state = {
  classroom: null,
  slug: null,
  authorize: false,
  students: [],
  student: null,
  modules: [],
  module: null,
  session: null,
  questions: [],
  inSessionAudio: null,
  remainingQuestions: [],
  questionCount: 0,
  completed: false,
  question: null,
  passcode: null,
  newSet: false,
  languages: [],
  catLanguage: 'en',
  questionAssists: null,
  questionAssist: null,
  isAssist: false,
  fetchingQuestions: false,
  isRecording: false,
  gumStream: null,
  recorder: null,
  verificationRecorder: null,
  recordingUrl: null,
  verificationRecordingUrl: null,
  audioFile: null,
  audioVerificationFile: null,
  recordingTimeOutId: null,
  micAccess: null,
  moduleType: null,
  earlyReadingAudioHasPlayed: false,
  isFullScreen: false,
}

export const getters = {
  classroom: state => state.classroom,
  slug: state => state.slug,
  passcode: state => {
    return state.passcode
  },
  authorize: state => state.authorize,
  students: state => state.students,
  student: state => state.student,
  modules: state => state.modules,
  module: state => state.module,
  questions: state => state.questions,
  inSessionAudioData: state => state.inSessionAudio,
  remainingQuestions: state => state.remainingQuestions,
  questionCount: state => state.questionCount,
  question: state => state.question,
  encodedAnswer: state => state.question.encoded_answer,
  completed: state => state.completed,
  session: state => state.session,
  newSet: state => state.newSet,
  languages: state => state.languages,
  catLanguage: state => state.catLanguage,
  questionAssists: state => state.questionAssists,
  questionAssist: state => state.questionAssist,
  isAssist: state => state.isAssist,
  isRecording: state => state.isRecording,
  micAccess: state => state.micAccess,
  recordingUrl: state => state.recordingUrl,
  fetchingQuestions: state => state.fetchingQuestions,
  moduleType: state => state.moduleType,
  inPracticeMode: state => {
    if (!state.student) {
      return false
    }
    return state.student.in_practice_mode
  },
  earlyReadingAudioHasPlayed: state => state.earlyReadingAudioHasPlayed,
  isFullScreen: state => state.isFullScreen,

  filteredModules(state) {
    return state.modules.filter(module => {
      if (state.moduleType === 'reading') {
        return (
          module.type === 'reading-fluency' || module.type === 'early-reading'
        )
      }
      return module.type === state.moduleType
    })
  },

  language(state) {
    const slug = state.student ? state.student.language : 'en'

    return find(state.languages, { slug })
  },

  previousQuestion(state) {
    let index = findLastIndex(state.questions, question => {
      return question.result !== null
    })

    if (index >= 0) {
      return state.questions[index]
    }
  },
  latestQuestion(state) {
    return state.questions[state.questions.length - 1]
  },

  isOver(state) {
    return state.questionCount >= state.remainingQuestions.length + 1
  },

  hasBonus(state) {
    return state.questions.length > get(state.module, 'total_questions', 10)
  },

  totalQuestions(state) {
    return state.remainingQuestions.length + 1
  },
}

export const mutations = {
  SET_CLASSROOM(state, classroom) {
    state.classroom = classroom
  },

  SET_STUDENTS(state, students) {
    state.students = students
  },

  SET_STUDENT(state, student) {
    state.student = student
  },

  SET_CLASSROOM_SLUG(state, slug) {
    state.slug = slug
  },

  SET_NEW_SET(state, newSet) {
    state.newSet = newSet
  },

  SET_SESSION(state, session) {
    state.session = session
  },

  SET_FETCHING_QUESTIONS(state, status) {
    state.fetchingQuestions = status
  },

  SET_PASSCODE(state, passcode) {
    state.passcode = passcode
    Cookies.set('passcode', passcode, {
      expires: 1 / 12, // expires in 2 hours
    })
  },

  SET_BADGE(state, badgeData) {
    state.session.badge = badgeData
  },

  SET_COMPLETED(state, completed) {
    state.completed = completed
  },

  AUTHORIZE_STUDENT(state, authorize) {
    state.authorize = authorize
  },

  SET_MODULES(state, modules) {
    state.modules = map(modules, module => {
      module.stats = head(module.stats)
      return module
    })
    if (!state.moduleType) {
      state.moduleType = find(state.modules, { type: 'math' })
        ? 'math'
        : modules.length
          ? 'reading'
          : 'math'
    }
  },

  SET_MODULE(state, module) {
    state.module = module
  },

  SET_MODULE_TYPE(state, type) {
    state.moduleType = type
  },

  SET_QUESTIONS(state, questions) {
    state.questions = questions
  },

  SET_REMAINING_QUESTIONS(state, questions) {
    state.remainingQuestions.push(questions)
  },

  SET_REMAINING_ALL_QUESTIONS(state, questions) {
    state.remainingQuestions = questions
  },

  SET_IN_SESSION_AUDIO(state, inSessionAudio) {
    state.inSessionAudio = inSessionAudio
  },

  EMPTY_REMAINING_QUESTIONS(state) {
    state.remainingQuestions = []
  },

  SET_QUESTION_ASSISTS(state, questionAssists) {
    state.questionAssists = questionAssists
  },

  SET_IS_ASSIST(state, isAssist) {
    state.isAssist = isAssist
  },

  ADD_QUESTION(state, question) {
    question = assign(question, {
      result: null,
    })

    state.questions.push(question)

    if (!get(question, 'properties')) {
      const index = state.questions.indexOf(question)
      state.questions.splice(index, 1)
    }
  },

  INCREMENT_QUESTION(state, payload) {
    state.questionCount += payload
  },

  RESET_QUESTION_COUNT(state) {
    state.questionCount = 0
  },

  SET_QUESTION(state, question) {
    state.question = question
  },

  // SET_QUESTION_RESULT(state, question) {
  //   let index = findIndex(state.questions, { id: question.id });

  //   state.questions[index].result = question.result;
  // },

  SET_QUESTION_RESULT(state, result) {
    let index = findIndex(state.questions, { id: state.question.id })

    state.questions[index].result = result
  },

  SET_QUESTION_DRAWING(state, drawing) {
    let index = findIndex(state.questions, { id: state.question.id })

    state.questions[index].drawing = drawing
  },

  INSERT_ANSWER(state, answer) {
    let index = findIndex(state.questions, { id: state.question.id })

    assign(state.questions[index], {
      answer,
    })
  },

  INSERT_ANSWERED_AT(state, timestamp) {
    let index = findIndex(state.questions, { id: state.question.id })

    assign(state.questions[index], {
      answered_at: timestamp,
    })
  },

  INSERT_AUDIO_ACTIVATED(state, status) {
    let index = findIndex(state.questions, { id: state.question.id })

    assign(state.questions[index], {
      text_to_speech: status,
    })
  },

  SET_LANGUAGES(state, languages) {
    state.languages = languages
  },

  SET_CAT_LANGUAGE(state, language) {
    state.catLanguage = language
  },

  SET_IS_RECORDING(state, status) {
    state.isRecording = status
  },
  SET_RECORDER(state, data) {
    state.recorder = data
  },
  SET_GUM_STREAM(state, data) {
    state.gumStream = data
  },
  SET_VERIFICATION_RECORDING_URL(state, data) {
    state.verificationRecordingUrl = data
  },
  SET_RECORDING_URL(state, data) {
    state.recordingUrl = data
  },
  START_RECORDER(state) {
    // Start the recording.
    state.recorder.start()
  },
  STOP_RECORDER(state) {
    if (!state.recorder) {
      return false
    }
    // Start the recording.
    state.recorder.stop()
  },
  SET_AUDIO_VERIFICATION_RECORDER(state, data) {
    state.verificationRecorder = data
  },
  START_AUDIO_VERIFICATION_RECORDER(state) {
    // Start the recording.
    state.verificationRecorder.start()
  },
  PAUSE_AUDIO_VERIFICATION_RECORDER(state) {
    if (!state.verificationRecorder) {
      return false
    }
    // Start the recording.
    state.verificationRecorder.pause()
  },
  RESUME_AUDIO_VERIFICATION_RECORDER(state) {
    if (!state.verificationRecorder) {
      return false
    }
    // Start the recording.
    state.verificationRecorder.resume()
  },
  STOP_AUDIO_VERIFICATION_RECORDER(state) {
    if (!state.verificationRecorder) {
      return false
    }
    // Start the recording.
    state.verificationRecorder.stop()
  },
  SET_AUDIO_VERIFICATION_FILE(state, data) {
    state.audioVerificationFile = data
  },
  SET_AUDIO_FILE(state, data) {
    state.audioFile = data
  },
  SET_RECORDING_TIME_OUT_ID(state, id) {
    state.recordingTimeOutId = id
  },
  SET_MIC_ACCESS(state, status) {
    state.micAccess = status
  },
  SET_EARLY_READING_AUDIO_HAS_PLAY(state, status) {
    state.earlyReadingAudioHasPlayed = status
  },
  SET_IS_FULL_SCREEN(state, status) {
    state.isFullScreen = status
  },
}

export const actions = {
  async fetchClassroom({ commit, getters }, passcode) {
    const { data } = await axios.get('classrooms', {
      params: {
        classroom: getters.slug,
        passcode,
      },
    })

    commit('SET_PASSCODE', passcode)
    commit('SET_CLASSROOM', data.data)
    commit('AUTHORIZE_STUDENT', true)
  },

  validateClassroom({ commit }, slug) {
    commit('SET_CLASSROOM_SLUG', slug)

    return axios.get(`classrooms/${slug}`)
  },
  async updateGradeLevel(
    { commit },
    { name, studentId, gradeLevelId, classroom }
  ) {
    const { data } = await axios.patch(`students/${studentId}`, {
      name,
      classroom,
      student: studentId,
      grade_level_id: gradeLevelId,
    })

    commit('SET_STUDENT', data.data)
  },
  async fetchStudents({ commit, getters }) {
    const { data } = await axios.get('students', {
      params: {
        classroom: getters.slug,
      },
    })

    commit('SET_STUDENTS', data.data)
  },

  async fetchQuestionAssists({ commit, getters }, { student, classroom }) {
    const { data } = await axios.get('assists', {
      params: {
        classroom: classroom,
        passcode: getters.passcode,
        student_id: student,
      },
    })

    commit('SET_QUESTION_ASSISTS', data.data)
  },

  insertAudioActivated({ commit }, status) {
    commit('INSERT_AUDIO_ACTIVATED', status)
  },

  selectQuestionAssist({ commit }, questionAssist) {
    commit('SET_QUESTION', questionAssist)
    commit('SET_MODULE', questionAssist.module)
    commit('SET_IS_ASSIST', true)
  },

  stopAssist({ commit }) {
    commit('SET_QUESTION', null)
    commit('SET_MODULE', null)
    commit('SET_IS_ASSIST', false)
  },

  async requestAssistance({ getters }, drawing) {
    return axios.post(`questions/${getters.question.id}/assistance`, {
      session_id: getters.session.id,
      drawing: drawing,
    })
  },

  async updateDrawing({ getters }, { drawing, sessionId, socketId }) {
    await axios.put(
      `questions/${getters.question.id}/assistance`,
      {
        drawing: drawing,
        session_id: sessionId,
        user_id: getters.student.id,
      },
      {
        headers: {
          'X-Socket-ID': socketId,
        },
      }
    )
  },

  async addStudent({ commit, getters }, name) {
    const { data } = await axios.post('students', {
      classroom: getters.slug,
      passcode: getters.passcode,
      name,
    })

    commit('SET_STUDENT', data.data)
  },

  selectStudent({ commit }, student) {
    commit('SET_STUDENT', student)
  },

  async fetchStudent({ commit, getters }, { classroom, student }) {
    const { data } = await axios.get(`students/${student}`, {
      params: {
        classroom,
        passcode: getters.passcode,
      },
    })

    commit('SET_CLASSROOM_SLUG', classroom)
    commit('SET_STUDENT', data.data)
  },

  async fetchModules({ commit, getters }, passcode = null) {
    const { data } = await axios.get('modules', {
      params: {
        // type: 'math',
        classroom: getters.slug,
        passcode: passcode || getters.passcode,
        student_id: getters.student.id,
      },
    })

    if (getters.module) {
      let latestModule = find(data.data, { id: getters.module.id })
      if (!isEqual(latestModule, getters.module)) {
        commit('SET_MODULE', latestModule)
      }
    }
    commit('SET_MODULES', data.data)
  },

  async fetchModuleByImmutableId({ commit, getters }, immutableId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`module/${immutableId}`, {
          params: {
            classroom: getters.slug,
            passcode: getters.passcode,
          },
        })
        .then(response => {
          commit('SET_MODULE', response.data.module)
          return resolve(response)
        })
        .catch(error => {
          return reject(error)
        })
    })
  },

  selectModule({ commit }, module) {
    commit('SET_COMPLETED', false)
    commit('SET_MODULE', module)
  },

  decodeCurrentQuestionAnswer({ state }) {
    return decodeAnswer(state.question.answer)
  },

  async createMathSession({ commit, getters }) {
    commit('SET_FETCHING_QUESTIONS', true)
    await axios
      .post(`/student/${getters.student.id}/session/math`, {
        module_id: getters.module.id,
        classroom: getters.slug,
        passcode: getters.passcode,
      })
      .then(({ data }) => {
        commit('SET_SESSION', data.session)
        commit('SET_QUESTIONS', [])
        commit('ADD_QUESTION', data.questions[0])
        commit(
          'SET_QUESTION',
          assign(data.questions[0], {
            result: null,
          })
        )
        data.questions.shift()
        commit('SET_REMAINING_ALL_QUESTIONS', data.questions)
        commit('SET_IN_SESSION_AUDIO', data.in_session_audio)
        commit('SET_COMPLETED', false)
        commit('SET_FETCHING_QUESTIONS', false)
      })
  },

  async createReadingFluencySession({ commit, getters }) {
    commit('SET_FETCHING_QUESTIONS', true)
    await axios
      .post(`/student/${getters.student.id}/session/reading-fluency`, {
        module_id: getters.module.id,
      })
      .then(({ data }) => {
        commit('SET_SESSION', data.session)
        commit('SET_QUESTIONS', [])
        commit('ADD_QUESTION', data.questions[0])
        commit(
          'SET_QUESTION',
          assign(data.questions[0], {
            result: null,
          })
        )
        data.questions.shift()
        commit('SET_REMAINING_ALL_QUESTIONS', data.questions)
        commit('SET_IN_SESSION_AUDIO', data.in_session_audio)
        commit('SET_COMPLETED', false)
        commit('SET_FETCHING_QUESTIONS', false)
      })
  },

  async createCatSession({ commit, getters }) {
    commit('SET_FETCHING_QUESTIONS', true)
    await axios
      .post(`/student/${getters.student.id}/session/cat`, {
        module_id: getters.module.id,
        classroom: getters.slug,
        passcode: getters.passcode,
      })
      .then(({ data }) => {
        commit('SET_SESSION', data.session)
        commit('SET_QUESTIONS', [])
        commit('ADD_QUESTION', data.questions[0])
        commit(
          'SET_QUESTION',
          assign(data.questions[0], {
            result: null,
          })
        )
        data.questions.shift()
        commit('SET_REMAINING_ALL_QUESTIONS', data.questions)
        commit('SET_IN_SESSION_AUDIO', data.in_session_audio)
        commit('SET_COMPLETED', false)
        commit('SET_FETCHING_QUESTIONS', false)
      })
  },

  async getEarlyReadingBonusQuestion({ commit }, session) {
    commit('SET_FETCHING_QUESTIONS', true)
    await axios
      .get(`/early-reading/session/${session.id}/bonus`)
      .then(({ data }) => {
        commit('SET_SESSION', data.session)
        commit('ADD_QUESTION', data.bonus_question[0])
        commit(
          'SET_QUESTION',
          assign(data.bonus_question[0], {
            result: null,
          })
        )
        commit('SET_FETCHING_QUESTIONS', false)
        commit('INCREMENT_QUESTION', 1)
      })
  },

  async submitMathQuestions({ commit, getters }, isAbort = false) {
    return new Promise((resolve, reject) => {
      let questions = getters.questions.filter(
        question => question.result !== null
      )
      let uri = `/session/${getters.session.id}/math/submission`

      axios
        .post(uri, {
          module_id: getters.module.id,
          classroom: getters.slug,
          passcode: getters.passcode,
          questions: questions,
          is_abort: isAbort,
        })
        .then(({ data }) => {
          if (isAbort) {
            return resolve(data)
          }
          commit('SET_SESSION', data.test_session)
          if (data.bonus_question) {
            commit('ADD_QUESTION', data.bonus_question[0])
            commit(
              'SET_QUESTION',
              assign(data.bonus_question[0], {
                result: null,
              })
            )
            commit('SET_FETCHING_QUESTIONS', false)
            commit('INCREMENT_QUESTION', 1)
            return resolve(data)
          }
          commit('SET_COMPLETED', true)
          resolve(data)
        })
        .catch(error => {
          return reject(error)
        })
    })
  },

  async createEarlyReadingSession({ commit, getters }) {
    commit('SET_FETCHING_QUESTIONS', true)
    await axios
      .post(`/student/${getters.student.id}/session/early-reading`, {
        module_id: getters.module.id,
        classroom: getters.slug,
        passcode: getters.passcode,
      })
      .then(({ data }) => {
        commit('SET_SESSION', data.session)
        commit('SET_QUESTIONS', [])
        commit('ADD_QUESTION', data.questions[0])
        commit(
          'SET_QUESTION',
          assign(data.questions[0], {
            result: null,
          })
        )
        data.questions.shift()
        commit('SET_REMAINING_ALL_QUESTIONS', data.questions)
        commit('SET_IN_SESSION_AUDIO', data.in_session_audio)
        commit('SET_COMPLETED', false)
        commit('SET_FETCHING_QUESTIONS', false)
      })
  },

  async fetchRemainingQuestions({ commit, getters }) {
    let totalQuestions = getters.session.module.total_questions - 1

    if (getters.session.module.type == 'reading-fluency') {
      totalQuestions = 1
    }

    commit('SET_FETCHING_QUESTIONS', true)

    for (let i = 1; i <= totalQuestions; i++) {
      await axios
        .post(
          `/remaining-questions/`,
          {
            session_id: getters.session.id,
            module_id: getters.module.id,
            classroom: getters.slug,
            passcode: getters.passcode,
          },
          {
            cancelToken: window.cancelSource.token,
          }
        )
        .then(({ data }) => {
          commit('SET_REMAINING_QUESTIONS', data.data)

          // cacheAssets(data.data, getters.offline);
        })
        .catch(() => {
          return false
        })
    }

    commit('SET_FETCHING_QUESTIONS', false)
  },

  async submitAllEarlyReadingQuestionsToApi(
    { commit, getters },
    isAbort = false
  ) {
    return new Promise((resolve, reject) => {
      let questions = getters.questions.filter(
        question => question.result !== null
      )
      let uri = `/session/${getters.session.id}/early-reading/submission`
      axios
        .post(uri, {
          module_id: getters.module.id,
          questions: questions,
          is_abort: isAbort,
        })
        .then(({ data }) => {
          if (isAbort) {
            return resolve(data)
          }
          commit('SET_SESSION', data.test_session)
          if (data.bonus_question) {
            commit('ADD_QUESTION', data.bonus_question[0])
            commit(
              'SET_QUESTION',
              assign(data.bonus_question[0], {
                result: null,
              })
            )
            commit('SET_FETCHING_QUESTIONS', false)
            commit('INCREMENT_QUESTION', 1)
            return resolve(data)
          }
          commit('SET_COMPLETED', true)
          resolve(data)
        })
        .catch(error => {
          return reject(error)
        })
    })
  },

  async updateSession({ commit, getters }) {
    const { data } = await axios.get(
      `/students/${getters.student.id}/sessions/${getters.session.id}`,
      {
        params: {
          classroom: getters.slug,
          passcode: getters.passcode,
        },
      }
    )

    commit('SET_SESSION', data.data)
  },

  setCurrentQuestion({ commit }, question) {
    commit('SET_QUESTION', question)
  },

  async submitAnswer({ commit, getters }, formParams) {
    return new Promise((resolve, reject) => {
      let result = checkAnswer(getters.question.answer, formParams.answer)

      commit('SET_QUESTION_RESULT', result)
      commit('INSERT_ANSWER', formParams.answer)
      commit('INSERT_ANSWERED_AT', generateTimestamp())

      if (getters.inPracticeMode && !result) {
        return resolve({ answer: result })
      }

      if (getters.questionCount < 9) {
        commit(
          'ADD_QUESTION',
          getters.remainingQuestions[getters.questionCount]
        )
        commit(
          'SET_QUESTION',
          getters.remainingQuestions[getters.questionCount]
        )
      }
      commit('INCREMENT_QUESTION', 1)
      resolve({ answer: result })
    })
  },

  nextQuestionAfterPracticeModeFeedback({ commit, getters }) {
    if (getters.questionCount < 9) {
      commit('ADD_QUESTION', getters.remainingQuestions[getters.questionCount])
      commit('SET_QUESTION', getters.remainingQuestions[getters.questionCount])
    }
    commit('INCREMENT_QUESTION', 1)
  },

  async submitCatAnswer({ getters, commit }, formParams) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/session/${getters.session.id}/cat/submission`,
          assign(formParams, {
            session_id: getters.session.id,
          })
        )
        .then(({ data }) => {
          //completed cat test
          if (!data.question) {
            commit('SET_BADGE', data.test_session.badge)
            commit('SET_SESSION', data.test_session)
            commit('SET_COMPLETED', true)
            return resolve(data)
          }
          commit('ADD_QUESTION', data.question)
          commit('SET_QUESTION', data.question)
          commit('SET_COMPLETED', false)
          return resolve(data)
        })
    })
  },

  async abortSession({ commit, getters }) {
    if (!getters.completed && getters.session) {
      let questions = getters.questions.filter(
        question => question.result !== null
      )
      await axios.delete(
        `session/${getters.session.id}/${getters.module.type}/submission`,
        {
          data: {
            questions: questions,
            module_id: getters.module.id,
            session: getters.session.id,
          },
        }
      )

      commit('SET_MODULE', null)
      commit('SET_SESSION', null)
      commit('SET_QUESTION', null)
      commit('EMPTY_REMAINING_QUESTIONS')
      commit('SET_QUESTIONS', [])
      commit('RESET_QUESTION_COUNT')
      commit('SET_COMPLETED', false)
    }
  },

  async abortReadingFluencySession({ commit, getters }) {
    if (getters.session) {
      if (state.recordingTimeOutId) {
        clearTimeout(state.recordingTimeOutId)
      }

      let formData = new FormData()
      formData.append('timed_out', false)
      formData.append('audio_file', state.audioFile)
      formData.append('session_id', state.session.id)
      // formData.append('module_item_immutable_id', state.question.module_item_immutable_id);
      formData.append('question_id', state.question.id)
      await axios.post(
        `session/${getters.session.id}/reading-fluency/abort`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )

      commit('SET_MODULE', null)
      commit('SET_SESSION', null)
      commit('SET_QUESTION', null)
      commit('EMPTY_REMAINING_QUESTIONS')
      commit('SET_QUESTIONS', [])
      commit('RESET_QUESTION_COUNT')
      commit('SET_COMPLETED', false)
    }
  },

  replayStudentSession({ commit }) {
    commit('SET_QUESTIONS', [])
    commit('RESET_QUESTION_COUNT')
    commit('EMPTY_REMAINING_QUESTIONS')
  },

  endSession({ commit }) {
    commit('SET_MODULE', null)
    commit('SET_SESSION', null)
    commit('SET_QUESTION', null)
    commit('EMPTY_REMAINING_QUESTIONS')
    commit('SET_QUESTIONS', [])
    commit('RESET_QUESTION_COUNT')
    commit('SET_COMPLETED', false)
  },

  complete({ commit }) {
    commit('SET_COMPLETED', true)
  },

  takeBonusQuestion({ commit }, question) {
    commit(
      'SET_QUESTION',
      assign(question, {
        result: null,
      })
    )
    commit('INCREMENT_QUESTION', 1)
  },

  setIsRecording({ commit }, status) {
    commit('SET_IS_RECORDING', status)
  },

  promptForMicAccess({ commit }) {
    commit('SET_MIC_ACCESS', 'prompt')
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(function () {
        commit('SET_MIC_ACCESS', 'granted')
      })
      .catch(function () {
        commit('SET_MIC_ACCESS', 'denied')
      })
  },

  startAudioRecording({ commit, state }) {
    commit('SET_MIC_ACCESS', 'prompt')
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then(stream => {
        commit('SET_MIC_ACCESS', 'granted')
        // Collection for recorded data.
        let data = []

        // Recorder instance using the stream.
        // Also set the stream as the src for the audio element.
        commit('SET_RECORDER', new MediaRecorder(stream))
        commit('SET_GUM_STREAM', stream)

        state.recorder.addEventListener('start', () => {
          // Empty the collection when starting recording.
          data.length = 0
        })

        state.recorder.addEventListener('dataavailable', event => {
          // Push recorded data to collection.
          data.push(event.data)
        })

        // Create a Blob when recording has stopped.`
        state.recorder.addEventListener('stop', () => {
          let recordingBlob = new Blob(data)
          commit('SET_AUDIO_FILE', recordingBlob)
          commit('SET_RECORDING_URL', URL.createObjectURL(recordingBlob))
        })

      })
      .catch(() => {
        commit('SET_MIC_ACCESS', 'denied')
      })
  },

  async stopAudioRecording({ commit }) {
    commit('STOP_RECORDER')
    commit('SET_IS_RECORDING', false)
    state.gumStream.getAudioTracks()[0].stop()
  },

  async stopAudioVerificationRecording({ commit }) {
    commit('STOP_AUDIO_VERIFICATION_RECORDER')
    commit('SET_IS_RECORDING', false)
    state.gumStream.getAudioTracks()[0].stop()
  },



  submitAudioRecording({ state, getters }, timedOut) {
    if (state.recordingTimeOutId) {
      clearTimeout(state.recordingTimeOutId)
    }
    return new Promise((resolve, reject) => {
      let formData = new FormData()
      formData.append('timed_out', timedOut)
      formData.append('audio_file', state.audioFile)
      formData.append('session_id', state.session.id)
      // formData.append('module_item_immutable_id', state.question.module_item_immutable_id);
      formData.append('question_id', state.question.id)
      axios
        .post(
          `/session/${getters.session.id}/reading-fluency/submission`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then(
          response => {
            return resolve(response)
          },
          error => {
            reject(error)
          }
        )
    })
  },

  async setLanguage({ commit, getters }, language) {
    const { data } = await axios.patch(`students/${getters.student.id}`, {
      classroom: getters.slug,
      passcode: getters.passcode,
      language,
    })

    commit('SET_STUDENT', data.data)
  },

  async fetchLanguages({ commit }) {
    const { data } = await axios.get('languages')

    commit('SET_LANGUAGES', data.data)
  },

  setCatLanguage({ commit }, language) {
    commit('SET_CAT_LANGUAGE', language)
  },

  async fetchUser({ commit }) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })
    instance.defaults.withCredentials = true

    const response = await instance.get('me')

    commit('SET_STUDENT', response.data.data.student)
    commit('SET_STUDENT', response.data.data.student)
    commit('SET_CLASSROOM', response.data.data.student.classroom)
    commit('SET_CLASSROOM_SLUG', response.data.data.student.classroom.slug)

    return response
  },

  async login({ getters }, password) {
    const instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })
    instance.defaults.withCredentials = true

    await instance.post('login', {
      email: getters.student.username,
      password: password,
    })
  },

  async updatePassword(
    { getters },
    { password, password_confirmation, current_password }
  ) {
    await axios.patch(`change-password`, {
      current_password,
      password,
      password_confirmation,
    })
  },

  updateModuleType({ commit }, type) {
    commit('SET_MODULE_TYPE', type)
  },
}
